import { Suspense, useEffect, useState } from "react";
import readFile from "../helpers/fileReader";
import CustomMarkdown from "./CustomMarkdown";

const path = "./content";
const currentUrl = window.location.href;

const getContent = async (orderedArray) => {
  const contentArray = await Promise.all(
    orderedArray.map(async (name) => {
      const content = await readFile(`${path}/${name}`);
      return <CustomMarkdown key={name} content={content} />;
    })
  );
  return contentArray;
};

const vast = await readFile(`./content/vast.md`);
const fortressfilms = await readFile(`./content/fortressfilms.md`);

const Sections = ({ contentFileNames }) => {
  /*
  const [content, setContent] = useState(null);

  useEffect(() => {
    let orderedArray = [...contentFileNames];
    const index = contentFileNames.findIndex((str) => currentUrl.includes(str));

    if (index !== -1) {
      const elementToMove = orderedArray.splice(index, 1)[0];
      orderedArray.unshift(elementToMove);
    }
    getContent(orderedArray).then((data) => setContent(data));
  });

  return <Suspense fallback={"Loading..."}>{content}</Suspense>;*/

  useEffect(() => {
    //if (currentUrl.includes("technicalartist"))
    //document.getElementById("technicalartist-details").scrollIntoView();
  }, []);

  if (window.location.href.includes("technicalartist")) {
    return (
      <>
        <CustomMarkdown content={fortressfilms} />
        <br />
        <br />
        <CustomMarkdown content={vast} />
      </>
    );
  } else {
    return (
      <>
        <CustomMarkdown content={vast} />
        <br />
        <br />
        <CustomMarkdown content={fortressfilms} />
      </>
    );
  }
};

export default Sections;
