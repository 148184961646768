import { useEffect } from "react";
import CustomMarkdown from "../Components/CustomMarkdown";
import Sections from "../Components/Sections";
import readFile from "../helpers/fileReader";

const overview = await readFile(`./content/overview.md`);

const Resume = ({}) => {
  return (
    <div className="page-body">
      <CustomMarkdown content={overview} />
      <Sections contentFileNames={["vast.md", "fortressfilms.md"]} />
    </div>
  );
};

export default Resume;
