import React, { useEffect, useState } from "react";
import { FaArrowCircleUp, FaChevronCircleUp } from "react-icons/fa";

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);
  }, []);

  return (
    <div className="scroll-button">
      {visible && (
        <FaChevronCircleUp size={70} onClick={scrollToTop} color="#8c8c8c" />
      )}
    </div>
  );
};

export default ScrollButton;
