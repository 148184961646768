const readFile = async (path) => {
  let content = "";

  await fetch(path)
    .then((response) => response.text())
    .then((text) => {
      content = text;
    });
  return content;
};

export default readFile;
