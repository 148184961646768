import { useNavigate } from "react-router-dom";

const ContentSelector = ({ routes }) => {
  const navigate = useNavigate();

  return (
    <div className="contentroutes print-disabled">
      {routes.map((item, index) => {
        const isSelectedClass = window.location.href.includes(item.route)
          ? "selected"
          : "";

        return (
          <>
            <div
              className={`route ${item.name} ${isSelectedClass}`}
              onClick={() => {
                navigate(item.route);
              }}
            >
              {item.displayTitle}
            </div>
            {index < routes.length - 1 ? <span>|</span> : ""}
          </>
        );
      })}
    </div>
  );
};

export default ContentSelector;
