import { useNavigate } from "react-router-dom";

const ProjectCard = ({ name, displayTitle, description, redirect }) => {
  let navigate = useNavigate();
  return (
    <div
      className={`projectcard ${name}`}
      onClick={() => {
        navigate(redirect);
      }}
    >
      <div className={`cardimage`}></div>
      <div className={`title`}>{displayTitle}</div>
      <div>{description}</div>
    </div>
  );
};

export default ProjectCard;
