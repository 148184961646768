import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Components/Header";
import ScrollButton from "./Components/ScrollButton";
import Resume from "./pages/Resume";
import Portfolio from "./pages/Portfolio";
import ProjectCard from "./Components/ProjectCard";
import ContentSelector from "./Components/ContentSelector";

function App() {
  return (
    <div className="App">
      <header className="App-header"></header>
      <Header bigTitle={"Andrei-Tudor Paraschiv"} />

      <ScrollButton />
      <BrowserRouter>
        <ContentSelector
          routes={[
            {
              name: "resume",
              displayTitle: "Resume",
              route: "resume-technicalartist",
            },
            {
              name: "portfolio",
              displayTitle: "Portfolio",
              route: "portfolio",
            },
          ]}
        />
        <Routes>
          <Route path="resume" element={<Resume />} />
          <Route path="/resume-technicalartist" element={<Resume />} />
          <Route path="*" element={<Portfolio />} />
          <Route path="/portfolio/*" element={<Portfolio />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
