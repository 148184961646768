import { Suspense, useEffect } from "react";
import CustomMarkdown from "../Components/CustomMarkdown";
import Sections from "../Components/Sections";
import readFile from "../helpers/fileReader";
import ProjectCard from "../Components/ProjectCard";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const contentPathMapping = {
  "/emesisblue": await readFile("./content/emesisblue.md"),
  "/murderinc": await readFile("./content/murderinc.md"),
  "/vrescaperoom": await readFile("./content/vrescaperoom.md"),
};

const cards = (
  <>
    <ProjectCard
      name={"emesisblue"}
      displayTitle={"Emesis Blue"}
      redirect={"/emesisblue"}
    />
    <ProjectCard
      name={"murderinc"}
      displayTitle={"Murder Inc."}
      redirect={"/murderinc"}
    />
    <ProjectCard
      name={"vrescaperoom"}
      displayTitle={"VR Escape Room"}
      redirect={"/vrescaperoom"}
    />
  </>
);

const getMarkdown = (content) => {
  return <CustomMarkdown content={content} />;
};

const getContentRoutes = (mapping) => {
  return Object.keys(mapping).map((key) => {
    const markdown = getMarkdown(contentPathMapping[key]);
    return <Route key={key} path={key} element={markdown} />;
  });
};
const Portfolio = ({}) => {
  return (
    <div className="page-body">
      <Routes>
        <Route path={"*"} element={cards} />
        {getContentRoutes(contentPathMapping)}
      </Routes>
    </div>
  );
};

export default Portfolio;
